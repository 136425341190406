import { get } from '@rails/request.js'

document.addEventListener('turbo:load', function () {
  const accountSubdomainInput = document.querySelector(
    '#accounts-new #account_registration_subdomain, #accounts-create #account_registration_subdomain',
  )

  if (accountSubdomainInput) {
    accountSubdomainInput.addEventListener('change', async function () {
      try {
        const response = await get('/subdomain.js', {
          query: { d: this.value },
          responseKind: 'text',
        })

        if (response.ok) {
          const script = await response.text
          // Execute the returned script
          eval(script)
        } else {
          console.error('Failed to fetch subdomain data:', response.statusText)
        }
      } catch (error) {
        console.error('Error checking subdomain:', error)
      }
    })
  }
})
